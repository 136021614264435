<template>
  <div class="wrapper">
    <div class="top-bar">
      <img :src="logo" alt="logo" class="logo" />
      <div class="title">DEEPINK-STATUS</div>
      <div class="tools">
        <!-- <div class="time">LAST REFRESH 19:09:30</div> -->
        <div class="button" @click="refresh">Refresh Data</div>
      </div>
    </div>
    <div class="cards-wrapper vld-parent" ref="cards">
      <div
        class="card"
        v-for="(item, index) in list"
        :key="index"
        @click="clickCard(item)"
      >
        <img :src="icons[item.url]" :alt="item.name" class="logo" />
        <div class="title">{{ item.name }}</div>
        <div class="content">
          <div class="line">{{ item.url }}</div>
          <div class="line">共 {{ item.rank.length }} 个榜单</div>
        </div>
        <div class="split"></div>
        <div class="bottom">
          <div class="issues">{{ item.errNum }} issues</div>
          <div
            class="dot"
            :class="{ orange: item.errNum, green: !item.errNum }"
          ></div>
        </div>
      </div>
    </div>
    <sweet-modal ref="modal" class="modal">
      <div class="title" slot="title">
        <img class="icon" :src="icons[card.url]" />
        <div class="title-text">{{ card.name }}</div>
      </div>
      <vue-custom-scrollbar :settings="scrollSettings" class="content failed">
        <div class="line" v-for="(rank, index) in card.rank" :key="index">
          <div class="title">{{ rank.title }}</div>
          <div class="cats">
            <div
              class="cat"
              :class="{ 'success-text': !cat.valid, 'failed-text': cat.valid }"
              v-for="(cat, index) in rank.categories"
              :key="index"
            >
              {{ cat.meaning }}
            </div>
          </div>
        </div>
      </vue-custom-scrollbar>
    </sweet-modal>
  </div>
</template>

<script>
const axios = require("axios");
const icons = require("@/map");
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
export default {
  data() {
    return {
      icons,
      logo: require("@/assets/logo.svg"),
      list: [],
      card: {},
      scrollSettings: {
        suppressScrollX: true,
      },
    };
  },
  components: {
    vueCustomScrollbar,
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      if (this.list.length > 0) this.list = [];
      let loader = this.$loading.show({
        isFullPage: false,
        backgroundColor: "#f5f5f5",
        container: this.$refs.cards,
        canCancel: true,
        color: "#f1a198",
        onCancel: this.onCancel,
        lockScroll: true,
      });
      console.log(loader);
      setTimeout(async () => {
        await this.fetchDatas();
        loader.hide();
      }, 1000);
    },
    async fetchDatas() {
      let failed = await axios({
        url: "https://app.deepink.cn/app/v2/book/info/rank/failed/list",
      });
      let all = await axios({
        url: "https://app.deepink.cn/app/v2/book/info/rank/category",
      });
      failed = failed.data;
      all = all.data;
      let newAll = all.map((o) => {
        let rank = o.rank;
        let rankObjNew = rank.map((p) => {
          let catsArr = p.categories.map((q) => {
            return {
              meaning: q,
              valid: true,
            };
          });
          return {
            title: p.title,
            categories: catsArr,
          };
        });
        o.rank = rankObjNew;
        return o;
      });
      failed.forEach((e) => {
        let url = e.url;
        let obj = newAll.find((o) => o.url === url);
        let rank = obj.rank;
        let errRankObj = rank.find((o) => o.title == e.title);
        let errCatObj = errRankObj.categories.find((o) => o.meaning == e.cat);
        errCatObj.valid = false;
      });
      // let obj = newAll[0];
      newAll.forEach((o) => {
        let errNum = 0;
        let ranks = o.rank;
        ranks.forEach((rank) => {
          rank.categories.forEach((cat) => {
            if (!cat.valid) errNum++;
          });
        });
        o["errNum"] = errNum;
      });
      this.list = newAll;
    },
    clickCard(item) {
      this.card = item;
      console.log(this.card);
      this.$refs.modal.open();
    },
    onCancel() {
      console.log("User cancelled the loader.");
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  background: #f5f5f5;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 42px 0;
  box-sizing: border-box;
  .top-bar {
    width: 100%;
    max-width: 870px;
    padding: 1rem 1.2rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      width: 3.6rem;
      height: 3.6rem;
    }
    .title {
      margin-left: 1rem;
      font-size: 1.3rem;
      font-weight: 500;
      flex: 1;
    }
    .tools {
      .button {
        cursor: pointer;
        user-select: none;
        transition: background 120ms ease-in 0s;
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        height: 36px;
        border-radius: 3px;
        color: rgb(235, 87, 87);
        font-size: 0.9rem;
        line-height: 1;
        padding-left: 12px;
        padding-right: 12px;
        background: rgba(235, 87, 87, 0.06);
        font-weight: 500;
        box-shadow: rgb(15 15 15 / 10%) 0px 1px 2px,
          rgb(235 87 87 / 30%) 0px 0px 0px 1px inset;
        text-transform: none;
        letter-spacing: 0;
        text-shadow: none;
        &:hover {
          background: #fcebe9;
        }
        &:active {
          margin-top: 4px;
        }
      }
    }
  }
  .cards-wrapper {
    @media (max-width: 670px) {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1.5rem;
      row-gap: 1.5rem;
    }
    min-height: 70vh;
    max-width: 870px;
    width: 100%;
    box-sizing: border-box;
    padding: 42px 24px;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2rem;
    row-gap: 2rem;

    .card {
      @media (min-width: 670px) {
        width: 100%;
        height: 15rem;
      }
      cursor: pointer;
      user-select: none;
      width: 100%;
      box-sizing: border-box;
      height: 14rem;
      border-radius: 0.8rem;
      background: #fff;
      padding: 1rem;
      display: flex;
      flex-direction: column;

      &:active {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      }

      .logo {
        width: 2.5rem;
        height: 2.5rem;
      }

      .title {
        padding: 1rem 0;
        font-size: 1.2rem;
        font-weight: bold;
        flex: 1;
      }

      .line {
        color: #757575;
        font-size: 0.8rem;
        line-height: 1.4rem;
        letter-spacing: 0.8px;
        font-weight: 300;
      }

      .split {
        margin: 0.8rem 0;
        width: 100%;
        height: 1px;
        background: #eeeeee;
      }

      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .issues {
          font-size: 0.8rem;
          color: #bdbdbd;
        }

        .dot {
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
          z-index: 1;
        }

        .orange {
          border: 0.01rem solid #ff7043;
          background: #ff7043;
        }

        .green {
          border: 0.01rem solid #26a69a;
          background: #26a69a;
        }
      }
    }
  }

  .modal {
    ::v-deep .sweet-content {
      padding-left: 0;
      padding-right: 0;
    }
    .title {
      display: flex;
      align-items: center;
      .icon {
        margin-right: 1rem;
        width: 2.6rem;
        height: 2.6rem;
      }
      .title-text {
        font-size: 1rem;
        font-weight: bold;
      }
    }

    .content {
      padding: 0 32px;
      height: 50vh;
      overflow: auto;
      .line {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 0.5rem 0;

        .title {
          margin-right: 1.6rem;
          font-size: 0.8rem;
          color: #343a40;
          font-weight: bold;
          padding: 0.8rem 0;
        }

        .cats {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          justify-content: flex-start;
          // grid-template-columns: repeat(auto-fill, 6rem);
          line-height: 2;
          // column-gap: 0.6rem;
          // row-gap: 0.6rem;
          .cat {
            font-size: 0.9rem;
            text-align: left;
            padding-right: 20px;
            padding-top: 8px;
          }
        }

        .failed-text {
          color: #868e96;
        }

        .success-text {
          color: #f03e3e;
        }
      }
    }
  }
}
</style>
