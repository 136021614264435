<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    window.onresize = () => {
      this.setSize();
    };
  },
  created() {
    this.setSize();
  },
  methods: {
    setSize() {
      let width = window.innerWidth;
      let html = document.getElementsByTagName("html")[0];
      if (width > 670) {
        html.style["font-size"] = "16px";
      } else {
        html.style["font-size"] = "14px";
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  background: #f5f5f5;
  height: 100vh;
}
</style>
