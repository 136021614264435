module.exports = {
  "deepink.cn": require("@/assets/deepink.jpg"),
  "qidian.com": require("@/assets/qidian.svg"),
  "jjwxc.net": require("@/assets/jjwxc.svg"),
  "17k.com": require("@/assets/17k.svg"),
  "8kana.com": require("@/assets/8kana.png"),
  "book.tiexue.net": require("@/assets/tiexue.svg"),
  "dushu.baidu.com": require("@/assets/baidu.svg"),
  "ebtang.com": require("@/assets/ebtang.svg"),
  "faloo.com": require("@/assets/faloo.svg"),
  "gongzicp.com": require("@/assets/gongzicp.png"),
  "heiyan.com": require("@/assets/heiyan.png"),
  "idejian.com": require("@/assets/idejian.png"),
  "jingyu.com": require("@/assets/jingyu.png"),
  "kakuyomu.jp": require("@/assets/kakuyomu.svg"),
  "linovel.net": require("@/assets/linovel.svg"),
  "linovelib.com": require("@/assets/linovelib.svg"),
  "motie.com": require("@/assets/motie.svg"),
  "sfacg.com": require("@/assets/sfacg.png"),
  "shubl.com": require("@/assets/shubl.png"),
  "tadu.com": require("@/assets/tadu.png"),
  "wenku8.com": require("@/assets/wenku8.png"),
  "xiang5.com": require("@/assets/xiang5.png"),
  "xrzww.com": require("@/assets/xrzww.svg"),
  "youdubook.com": require("@/assets/youdubook.png"),
  "yuedu.163.com": require("@/assets/163.png"),
  "zongheng.com": require("@/assets/zongheng.svg"),
};
